
/* VARS */
:root{
  --dark : #ffffff;
  --white : #000000;
  --primary : #FFD300;
  --secondary : #333333;
}

/*  */
/* APP */
/*  */


#swup {
  position: absolute;
  z-index: 0;
}

.cursor-container{
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);

  g{
    transform-origin: center center;
  }
}